import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { useSendTestEmail } from "@circle-react/components/SettingsApp/Emails/hooks/useSendTestEmail";
import type { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { Button } from "@circle-react-shared/uikit/Button";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.builder";

interface EmailEditorHeaderProps {
  isSubmitting: boolean;
  update: ReturnType<typeof useUpdateBroadcast>;
  closeModal: () => void;
}

export const EmailEditorHeader = ({
  isSubmitting,
  update,
  closeModal,
}: EmailEditorHeaderProps) => {
  const { getValues } = useFormContext();
  const { mutateAsync } = update;

  const { handleTestEmail } = useSendTestEmail();

  const sendTestEmail = async () => {
    await mutateAsync({
      body: {
        broadcast_content: {
          email_content: getValues().email_content,
        },
      },
    });
    await handleTestEmail();
  };

  return (
    <FullScreenModal.Header className="md:!py-4">
      <div className="divide-primary flex items-center divide-x">
        <IconButton
          name="20-arrow-left"
          iconSize={20}
          buttonSize={28}
          className="text-default me-4"
          iconClassName="w-5 h-5"
          ariaLabel={t("back")}
          onClick={closeModal}
        />
        <div className="ps-4">
          <Typography.TitleSm weight="semibold" color="text-darkest">
            {t([localeNamespace, "email_editor"])}
          </Typography.TitleSm>
        </div>
      </div>
      <div className="flex shrink-0 gap-x-4">
        <Button variant="secondary" type="button" large onClick={sendTestEmail}>
          {t([localeNamespace, "send_a_test"])}
        </Button>
        <Button variant="circle" type="submit" large disabled={isSubmitting}>
          {isSubmitting ? t("saving") : t([localeNamespace, "save_and_exit"])}
        </Button>
      </div>
    </FullScreenModal.Header>
  );
};
