import { t } from "i18n-js";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@/react/helpers/communityMemberHelpers";
import { usePlanStatus } from "@/react/hooks/usePlanStatus";
import { usePunditUserContext } from "@circle-react/contexts";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers/settingsNavPath";
import { useIsMarketingHubEmailBroadcastsEnabled } from "@circle-react/hooks/useIsMarketingHubEmailBroadcastsEnabled";
import { useIsWorkflowsEnabled } from "@circle-react-shared/Workflows";
import type { SidebarLink } from "./types";

export const BACK_TO_COMMUNITY_PATH_KEY = "back-to-community";

export const useCollapsedSidebarLinksV2 = (): SidebarLink[] => {
  const { currentCommunityMember, currentCommunity } = usePunditUserContext();
  const isWorkflowsEnabled = useIsWorkflowsEnabled();
  const isHomePageEnabled = Boolean(currentCommunity?.home_page_enabled);
  const { isCommunityOnPlus } = usePlanStatus();

  const isAffiliatesEnabled = Boolean(
    currentCommunity.affiliates_feature_flag_enabled &&
      isCommunityAdmin(currentCommunityMember),
  );

  const hasEmail = useIsMarketingHubEmailBroadcastsEnabled();

  return [
    {
      pathKey: BACK_TO_COMMUNITY_PATH_KEY,
      label: t("settings_nav_v3.back_to_community.label"),
      icon: "20-back-to-community",
      path: isHomePageEnabled ? "/home" : "/",
      enabled:
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember),
    },
    {
      pathKey: "members",
      label: t("settings_nav_v3.members.label"),
      icon: "20-members",
      path: settingsNavPath.members.manage,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "email",
      label: t("settings_nav_v3.emails.label"),
      icon: "20-send",
      path: settingsNavPath.email.broadcast,
      enabled: hasEmail && isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "content",
      label: t("settings_nav_v3.content.label"),
      icon: "20-content",
      path: settingsNavPath.content.posts,
      enabled:
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember),
    },
    {
      pathKey: "workflows",
      label: t("settings_nav_v3.workflows.label"),
      icon: "20-workflows",
      path: settingsNavPath.workflows.all,
      enabled: isCommunityAdmin(currentCommunityMember) && isWorkflowsEnabled,
    },
    {
      pathKey: "paywalls",
      label: t("settings_nav_v3.payments.label"),
      icon: "20-payments",
      path: settingsNavPath.paywalls.index,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "plans",
      label: isCommunityOnPlus
        ? t("settings_nav_v3.plans.plus_label")
        : t("settings_nav_v3.plans.label"),
      icon: "20-plans",
      path: settingsNavPath.plans.general,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "analytics_v2",
      label: t("settings_nav_v3.analytics.label"),
      icon: "20-analytics",
      path: settingsNavPath.analytics_v2.index,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
    {
      pathKey: "affiliates",
      label: t("settings_nav_v3.paywalls_affiliates.label"),
      icon: "20-paywalls-affiliates",
      path: settingsNavPath.affiliates.settings,
      enabled: isAffiliatesEnabled,
    },
    {
      pathKey: "settings",
      label: t("settings_nav_v3.settings.label"),
      icon: "20-nav-settings",
      path: settingsNavPath.settings.general,
      enabled: isCommunityAdmin(currentCommunityMember),
    },
  ];
};
