import { t } from "i18n-js";
import { ProgressBar } from "../ProgressBar";
import { Video } from "../Video";

export interface MediaDirectUploaderProps {
  id: string;
  name: string;
  media: any;
  previewMedia: any;
  onMediaUpload: (event: any) => void;
  onRemoveMedia: () => void;
  isLoading: boolean;
  progress: number;
}

export const MediaDirectUploader = ({
  id,
  name,
  media,
  previewMedia,
  onMediaUpload,
  onRemoveMedia,
  isLoading,
  progress,
}: MediaDirectUploaderProps) => {
  const mediaPreview = () => {
    const type = previewMedia.type ? fileType(previewMedia) : fileType(media);
    const url = previewMedia.url ? previewMedia.url : media.url;

    return (
      <div className="media-upload-wrapper">
        {type === "video" ? (
          <Video url={url} />
        ) : (
          <img loading="lazy" alt="" src={url} />
        )}
      </div>
    );
  };

  const fileType = (file: any) => file["type"].split("/")[0];

  const actionButtons = () =>
    hasAttachedMedia() ? (
      <span className="btn btn-secondary btn-half pull-left mt-0">
        {t("replace")}
      </span>
    ) : (
      <span className="w-full text-center">
        {media ?? media["url"] ? t("replace") : t("upload")}
      </span>
    );

  const hasAttachedMedia = () =>
    (previewMedia["url"] && previewMedia["url"].length > 0) ||
    (media["url"] && media["url"].length > 0);

  return (
    <div className="form-input">
      <div
        data-controller="form"
        data-id="1"
        data-btn-update-text={t("upload")}
      >
        {isLoading && <ProgressBar progress={progress} />}
        {!isLoading && (
          <div>
            {hasAttachedMedia() && mediaPreview()}
            <label
              className={`mt-0 ${
                hasAttachedMedia() ? "" : "btn btn-secondary btn-full"
              } `}
            >
              {actionButtons()}
              <span className="hidden">
                <input
                  accept="image/*, video/*"
                  className="form-control"
                  type="file"
                  name={name}
                  id={id}
                  onChange={onMediaUpload}
                />
              </span>
            </label>
            {hasAttachedMedia() && (
              <button
                type="button"
                className="btn btn-secondary btn-half pull-right mt-0"
                onClick={onRemoveMedia}
              >
                {t("remove")}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
