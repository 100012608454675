import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import { Icon } from "@circle-react-shared/Icon";
import {
  EmptyAvatar,
  MediumRectangle,
  SquareWithBorder,
} from "@circle-react-shared/Placeholders";
import { Typography } from "@circle-react-uikit/Typography";

export const PostPreview = () => {
  const {
    currentCommunity: { root_url: demoUrl },
  } = usePunditUserContext();

  return (
    <div className="border-primary bg-primary w-full space-y-5 rounded-lg border px-6 py-5 pb-4 pr-8">
      <div className="flex items-center gap-3">
        <EmptyAvatar width={32} height={32} />
        <div className="flex h-[34px] flex-col">
          <Typography.LabelXs weight="bold">
            <span className="text-dark">{t("customize_ui.member_name")}</span>
          </Typography.LabelXs>
          <Typography.LabelXs>
            <span className="text-light">
              {t("customize_ui.one_month_ago")}
            </span>
          </Typography.LabelXs>
        </div>
      </div>
      <div className="space-y-[10px]">
        <MediumRectangle className="w-full" />
        <MediumRectangle className="w-full" />
        <MediumRectangle className="w-[50%]" />
      </div>
      <div>
        <span className="text-link pointer-events-none text-sm font-semibold">
          {demoUrl}
        </span>
      </div>
      <div className="flex w-min flex-row-reverse space-x-[-4px] space-x-reverse">
        <SquareWithBorder />
        <SquareWithBorder />
        <SquareWithBorder />
      </div>
      <div className="flex h-4 items-baseline gap-5">
        <div className="flex items-baseline gap-1">
          <Icon
            size={16}
            type="like"
            className="text-brand"
            useWithFillCurrentColor
          />
          <Typography.LabelXs weight="medium">
            <span className="text-brand font-semibold">{t("like")}</span>
          </Typography.LabelXs>
        </div>
        <div className="flex items-baseline gap-1">
          <Icon size={16} type="message" />
          <Typography.LabelXs weight="medium">
            <span className="text-light">{t("cap_comment")}</span>
          </Typography.LabelXs>
        </div>
      </div>
    </div>
  );
};
