import { t } from "i18n-js";
import { useButtonVariant } from "@circle-react/helpers/useButtonVariant";
import { useFilterContext } from "@circle-react-shared/Filter";
import type { BaseFilter } from "@circle-react-shared/MemberFilters/types";
import { Button } from "@circle-react-uikit/Button";
import { Fieldset } from "@circle-react-uikit/Fieldset";
import { Form } from "@circle-react-uikit/Form";
import { BaseFilterFields } from "../BaseFilterFields";

export interface RadioFilterParam extends BaseFilter {
  value?: string;
}

export interface RadioFilterOption {
  label: string;
  value: string;
}

export type RadioFilterOptions = RadioFilterOption[];

interface RadioFilterProps {
  options: RadioFilterOptions;
  param: RadioFilterParam;
  legend: string;
}

export const RadioFilter = ({ param, options, legend }: RadioFilterProps) => {
  const { onApply } = useFilterContext();
  const primaryButtonVariant = useButtonVariant();

  const getDefaultValues = (): RadioFilterParam => ({
    ...param,
    value: param.value ?? options[0].value,
  });

  const handleApply = (submitData: RadioFilterParam, { reset }: any) => {
    onApply(submitData);
    reset();
  };

  return (
    <div className="min-w-[18rem] p-4">
      <Form defaultValues={getDefaultValues()} onSubmit={handleApply}>
        <div className="flex flex-col gap-y-4 ">
          <Fieldset legend={legend} verticalSpacing="tight">
            <Form.Item
              name="value"
              hideLabel
              hideBorder
              hideDescription
              isFirstChild
            >
              <Form.RadioGroup name="value" variant="radio" options={options} />
            </Form.Item>
          </Fieldset>
          <BaseFilterFields />
          <Button variant={primaryButtonVariant} type="submit" full>
            {t("filters.apply")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
