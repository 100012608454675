import { useRef } from "react";
import type { Editor } from "@tiptap/react";
import type { BroadcastContentAttributes } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import type { useUpdateBroadcast } from "@circle-react/components/SettingsApp/Emails/hooks/useUpdateBroadcast";
import { FullScreenModal } from "@circle-react-shared/ModalsV2/FullScreenModal";
import { EmailEditorForm } from "./EmailEditorForm";
import { EmailEditorHeader } from "./Header";
import { TipTapEditor } from "./TipTapEditor";

interface EmailEditorProps {
  isOpen?: boolean;
  update: ReturnType<typeof useUpdateBroadcast>;
  onClose: () => void;
}

export const EmailEditor = ({
  isOpen = true,
  update,
  onClose,
}: EmailEditorProps) => {
  const { mutateAsync, isLoading: isSubmitting } = update;
  const editorRef = useRef<Editor>(null);

  const handleSubmit = async (data: {
    email_content: BroadcastContentAttributes["email_content"];
  }) => {
    await mutateAsync({
      body: {
        broadcast_content: {
          email_content: data.email_content,
        },
      },
    });
    // We have enabled preventAccidentalLeave on the Form. Close the modal after the form submit is successful.
    setTimeout(onClose);
  };

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={onClose}
      contentPortalId="edit-space-content-portal"
      shouldShowCloseButton
    >
      <EmailEditorForm onSubmit={handleSubmit}>
        <EmailEditorHeader
          isSubmitting={isSubmitting}
          update={update}
          closeModal={onClose}
        />
        <div
          id="email-editor-toolbar-portal"
          className="border-primary flex items-center justify-center border-b py-2.5"
        />
        <FullScreenModal.Body className="mx-auto w-full overflow-y-auto py-6 md:py-10">
          <TipTapEditor editorRef={editorRef} />
        </FullScreenModal.Body>
      </EmailEditorForm>
    </FullScreenModal>
  );
};
