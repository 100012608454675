import { t } from "i18n-js";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const SettingsLinks = () => {
  const { isV3Enabled } = useIsV3();

  const links: Link[] = [
    {
      label: t("settings_nav_v3.settings.general"),
      path: settingsNavPath.settings.general,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.custom_domain"),
      path: settingsNavPath.settings.custom_domain,
      exact: true,
    },
    {
      label: t("settings_nav_v3.settings.community_ai"),
      path: settingsNavPath.settings.community_ai,
      exact: true,
    },
  ];

  if (!isV3Enabled) {
    links.push({
      label: t("settings_nav_v3.settings.code_snippets"),
      path: settingsNavPath.settings.code_snippets,
      exact: true,
    });
  }

  links.push({
    label: t("settings_nav_v3.settings.weekly_digest"),
    path: settingsNavPath.settings.weekly_digest,
    exact: true,
  });

  if (!isV3Enabled) {
    links.push(
      ...[
        {
          label: t("settings_nav_v3.settings.mobile_app"),
          path: settingsNavPath.settings.mobile_app,
          exact: true,
        },
        {
          label: t("settings_nav_v3.settings.home"),
          path: settingsNavPath.settings.home,
          exact: true,
        },
      ],
    );
  }

  links.push(
    ...[
      {
        label: t("settings_nav_v3.settings.embed"),
        path: settingsNavPath.settings.embed,
        exact: true,
      },
      {
        label: t("settings_nav_v3.settings.single_sign_on"),
        path: settingsNavPath.settings.single_sign_on,
        exact: true,
      },
      {
        label: t("settings_nav_v3.settings.messaging"),
        path: settingsNavPath.settings.messaging,
        exact: true,
      },
      {
        label: t("settings_nav_v3.settings.api"),
        path: settingsNavPath.settings.api,
        exact: true,
      },
      {
        label: t("settings_nav_v3.settings.legal"),
        path: settingsNavPath.settings.legal,
        exact: true,
      },
    ],
  );

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.settings.label")}>
        {links.map(link => (
          <SubNavLinkItem key={link.path} label={link.label} path={link.path} />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
