import classNames from "classnames";
import { t } from "i18n-js";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  communitySignInUrl,
  communitySignupUrl,
  shouldShowSignupLink,
} from "@circle-react/helpers/communityHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useRouterQueryParams } from "@circle-react/hooks/useRouterQueryParams";
import { Button } from "@circle-react-uikit/Button";

export interface ConditionallyRenderLoginSignupButtonsProps {
  hideSignup?: boolean;
}

export const ConditionallyRenderLoginSignupButtons = ({
  hideSignup,
}: ConditionallyRenderLoginSignupButtonsProps) => {
  const { currentCommunity, currentUser } = usePunditUserContext();
  const { isV3Enabled } = useIsV3();
  const doesCommunityHavePrivateSignUpUrl = Boolean(
    currentCommunity?.private_sign_up_url,
  );
  const showSignupLink =
    shouldShowSignupLink(currentCommunity) || doesCommunityHavePrivateSignUpUrl;
  const signupUrl = doesCommunityHavePrivateSignUpUrl
    ? currentCommunity.private_sign_up_url
    : communitySignupUrl(currentCommunity);
  const { post_login_redirect: postLoginRedirectUrlParam } =
    useRouterQueryParams();

  if (currentUser) {
    return null;
  }

  return (
    <div
      className={classNames("hidden items-center justify-end md:flex", {
        "gap-4": !isV3Enabled,
        "gap-2.5": isV3Enabled,
      })}
    >
      <Button
        type="button"
        variant="secondary"
        large={isV3Enabled}
        onClick={() => {
          window.location.href = communitySignInUrl(currentCommunity, {
            post_login_redirect:
              postLoginRedirectUrlParam || window.location.href,
          });
        }}
      >
        {t("log_in")}
      </Button>
      {!hideSignup && showSignupLink && (
        <Button
          type="button"
          variant="primary"
          large={isV3Enabled}
          onClick={() => {
            window.location.href = signupUrl;
          }}
        >
          {doesCommunityHavePrivateSignUpUrl
            ? currentCommunity.private_sign_up_label
            : isV3Enabled
            ? t("join")
            : t("sign_up")}
        </Button>
      )}
    </div>
  );
};
