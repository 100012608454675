import { t } from "i18n-js";
import { useReportsAmounts } from "@circle-react/components/SettingsApp/Moderation/useReportsAmounts";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@circle-react/helpers/communityMemberHelpers";
import { settingsNavPath } from "@circle-react/helpers/urlHelpers";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const ContentLinks = () => {
  const { currentCommunitySettings, currentCommunityMember } =
    usePunditUserContext();
  const { inboxCount: moderationInboxCount } = useReportsAmounts();
  const { isV3Enabled } = useIsV3();

  const links: Link[] = [
    {
      label: t("settings_nav_v3.content.posts"),
      path: settingsNavPath.content.posts,
      isDisabled: !(
        isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember)
      ),
      exact: true,
    },
  ];

  if (!isV3Enabled) {
    links.push({
      label: t("settings_nav_v3.content.spaces"),
      path: settingsNavPath.content.spaces,
      isDisabled:
        !currentCommunitySettings?.spaces_crud_enabled ||
        !isCommunityAdmin(currentCommunityMember),
      exact: true,
    });
  }

  links.push(
    ...[
      {
        label: t("settings_nav_v3.content.moderation"),
        path: settingsNavPath.content.moderation,
        isDisabled: !(
          isCommunityAdmin(currentCommunityMember) ||
          isCommunityModerator(currentCommunityMember)
        ),
        badgeLabel:
          moderationInboxCount > 0 ? String(moderationInboxCount) : "",
        exact: true,
      },
      {
        label: t("settings_nav_v3.live_streams.label"),
        path: settingsNavPath.live_streams.live_streams,
        isDisabled: !isCommunityAdmin(currentCommunityMember),
        exact: true,
      },
      {
        label: t("settings_nav_v3.content.topics"),
        path: settingsNavPath.content.topics,
        isDisabled:
          !currentCommunitySettings?.topics_enabled ||
          !isCommunityAdmin(currentCommunityMember),
        exact: true,
      },
      {
        label: t("settings_nav_v3.content.bulk_logs"),
        path: settingsNavPath.content.posts_bulk_actions,
        isDisabled: !isCommunityAdmin(currentCommunityMember),
        exact: true,
      },
    ],
  );

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content title={t("settings_nav_v3.content.label")}>
        {links.map(link => (
          <SubNavLinkItem key={link.path} {...link} />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
