import { formattedDateTime } from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";

export const RenewalDateColumn = (cellContext: any) => {
  const {
    row: { original: subscription },
  } = cellContext;

  const renewalDateString =
    subscription.renews_on || subscription.next_billing_date
      ? formattedDateTime({
          dateTime: new Date(
            subscription.renews_on || subscription.next_billing_date,
          ),
          format: "long_date",
        })
      : " - ";

  return <div className="whitespace-normal">{renewalDateString}</div>;
};
