import { useMemo } from "react";
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { t } from "i18n-js";
import { useFormContext } from "react-hook-form";
import { FormTipTapBlockEditor } from "@circle-react-shared/uikit/Form/FormTipTapBlockEditor/FormTipTapBlockEditor";
import { ButtonDropdownContextProvider } from "./ButtonDropdownContext";
import { EmailEditorToolbar } from "./Toolbar";
import { useIsEmailAttachmentsEnabled } from "./useIsEmailAttachmentsEnabled";

interface TipTapBlockEditorProps {
  editorRef: RefObject<Editor | null>;
}

export function TipTapEditor({ editorRef }: TipTapBlockEditorProps) {
  const { getValues } = useFormContext();
  const emailContent = getValues("email_content");
  const isEmailAttachmentsEnabled = useIsEmailAttachmentsEnabled();

  const disabledExtensions = useMemo(() => {
    const defaultDisabledExtensions = [
      "mention",
      "embeds",
      "poll",
      "code_block",
    ];

    if (isEmailAttachmentsEnabled) return defaultDisabledExtensions;
    return [...defaultDisabledExtensions, "file"];
  }, [isEmailAttachmentsEnabled]);
  const disabledSlashMenuCategories = useMemo(() => {
    const defaultDisabledSlashMenuCategories = ["Embed"];
    if (isEmailAttachmentsEnabled) return defaultDisabledSlashMenuCategories;
    return [...defaultDisabledSlashMenuCategories, "Upload"];
  }, [isEmailAttachmentsEnabled]);

  return (
    <ButtonDropdownContextProvider>
      <div className="mx-auto max-w-screen-sm">
        <FormTipTapBlockEditor
          placeholder={t("email_editor.placeholder")}
          name="email_content.body"
          editorRef={editorRef}
          disabledExtensions={disabledExtensions}
          disabledSlashMenuCommands={[
            "code",
            "poll",
            "audio",
            "video",
            "pdf",
            "giphy",
          ]}
          disabledSlashMenuCategories={disabledSlashMenuCategories}
          headingLevels={[1, 2, 3]}
          toolbars={[
            {
              portalId: "email-editor-toolbar-portal",
              component: EmailEditorToolbar,
            },
          ]}
          sgidToObjectMap={emailContent.sgids_to_object_map}
          inlineAttachments={emailContent.inline_attachments}
          shouldPreviewFilesAsLinks
        />
      </div>
    </ButtonDropdownContextProvider>
  );
}
