export const SORT_OPTIONS = ["alphabetical", "oldest", "latest"] as const;

export type SortOptions = (typeof SORT_OPTIONS)[number];

export const VIEWS = {
  LIST: "list",
  CARDS: "cards",
} as const;

export type Views = (typeof VIEWS)[keyof typeof VIEWS];

export const STATUS_OPTIONS_MAP = {
  ALL: "all",
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;

export type StatusOptions =
  (typeof STATUS_OPTIONS_MAP)[keyof typeof STATUS_OPTIONS_MAP];

export const SORT_OPTIONS_MAP = {
  ALPHABETICAL: "alphabetical",
  OLDEST: "oldest",
  LATEST: "latest",
  ROLE: "role",
  ACTIVITY_SCORE: "activity_score",
} as const;

export type SortOptionsMap =
  (typeof SORT_OPTIONS_MAP)[keyof typeof SORT_OPTIONS_MAP];
