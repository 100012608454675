import type { Editor } from "@tiptap/core";
import * as yup from "yup";
import { t } from "@circle-react/custom_i18n";
import { useThemeContext } from "@circle-react/providers";
import { Button } from "@circle-react-shared/uikit/Button";
import { Form } from "@circle-react-shared/uikit/Form";
import { FormColorPicker } from "@circle-react-shared/uikit/Form/FormColorPicker";
import { FormInput } from "@circle-react-shared/uikit/Form/FormInput";
import { Item as FormItem } from "@circle-react-shared/uikit/Form/Item";
import { LinkInput } from "@circle-react-shared/uikit/Form/LinkInput";
import { prependHttps } from "@circle-react-shared/uikit/Form/LinkInput/prependHttps";
import { Typography } from "@circle-react-shared/uikit/Typography";

const localeNamespace = "settings.emails.builder";

const validationSchema = yup.object().shape({
  label: yup
    .string()
    .required(t([localeNamespace, "errors.label_is_required"])),
  url: yup
    .string()
    .transform((_, originalValue) => prependHttps(originalValue))
    .url(t([localeNamespace, "errors.url_is_required"]))
    .required(t([localeNamespace, "errors.url_is_required"])),
  color: yup
    .string()
    .matches(
      /^#[0-9A-Fa-f]{6}$/,
      t([localeNamespace, "errors.color_is_required"]),
    )
    .required(t([localeNamespace, "errors.color_is_required"])),
});

interface ButtonFormProps {
  editor: Editor;
  onClose: () => void;
}

export function ButtonForm({ editor, onClose }: ButtonFormProps) {
  const theme = useThemeContext();

  const getDefaultValue = () => {
    if (editor.isActive("cta")) {
      return editor.getAttributes("cta");
    }
    return {
      label: "",
      url: "",
      color: theme.brandColor.light ?? "#000000",
    };
  };
  const defaultValue = getDefaultValue();
  const isEdit = !!defaultValue.label;

  return (
    <div className="bg-primary border-primary mt-1 rounded-lg border shadow-xl">
      <div className="px-5 pb-2 pt-4">
        <Typography.LabelLg weight="semibold">
          {isEdit
            ? t("email_editor.cta.edit_title")
            : t("email_editor.cta.title")}
        </Typography.LabelLg>
      </div>
      <Form
        defaultValues={getDefaultValue()}
        onSubmit={attrs => {
          editor
            .chain()
            .focus()
            .insertContent({
              type: "cta",
              attrs,
            })
            .run();
          onClose();
        }}
        validationSchema={validationSchema}
        shouldStopPropagation
        noValidate
        className="px-5"
      >
        <div className="flex flex-col pb-4">
          <FormItem
            name="label"
            hidePlaceholder
            hideDescription
            hideBorder
            label={t("email_editor.cta.label")}
            required
          >
            <FormInput
              name="label"
              label={t("email_editor.cta.label")}
              placeholder={t("email_editor.cta.label")}
            />
          </FormItem>
          <FormItem
            name="url"
            hidePlaceholder
            hideDescription
            hideBorder
            label={t("email_editor.cta.url")}
            required
          >
            <LinkInput label={t("email_editor.cta.url")} name="url" />
          </FormItem>
          <FormItem
            name="color"
            hidePlaceholder
            hideDescription
            hideBorder
            label={t("email_editor.cta.color")}
            required
          >
            <FormColorPicker name="color" />
          </FormItem>
        </div>
        <div className="flex justify-end gap-x-3 pb-5 pt-2.5">
          <Button type="button" onClick={onClose} variant="secondary-light">
            {t("cancel")}
          </Button>
          <Button type="submit" variant="circle">
            {t("email_editor.cta.save")}
          </Button>
        </div>
      </Form>
    </div>
  );
}
