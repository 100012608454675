import { t } from "i18n-js";
// Columns
import { ActionMenuColumn } from "./Columns/ActionMenuColumn";
import { ChargesQuantityColumn } from "./Columns/ChargesQuantityColumn";
import { CouponCodeColumn } from "./Columns/CouponCodeColumn";
import { CustomerColumn } from "./Columns/CustomerColumn";
import { PaywallColumn } from "./Columns/PaywallColumn";
import { RenewalDateColumn } from "./Columns/RenewalDateColumn";
import { StartDateColumn } from "./Columns/StartDateColumn";
import { StatusColumn } from "./Columns/StatusColumn";
import { TermColumn } from "./Columns/TermColumn";

const localeRoot =
  "settings.paywalls_admin_dashboard.subscriptions.list.headers";

type COLUMNS_AVAILABLE =
  | "customer"
  | "status"
  | "term"
  | "startDate"
  | "renewalDate"
  | "actionMenu"
  | "paywall"
  | "couponCode"
  | "chargesQuantity";

interface CellProp {
  header: string;
  accessorKey: COLUMNS_AVAILABLE;
  cell: any;
  thClassName?: string;
  cellClassName?: string;
  canSort?: boolean;
}
type ColumnsPossibleToRenderProp = Record<COLUMNS_AVAILABLE, CellProp>;

export const orderingColumn: Record<COLUMNS_AVAILABLE, string> = {
  customer: "community_member_name",
  paywall: "paywall_name",
  status: "status",
  term: "subscription_term",
  startDate: "created_at",
  renewalDate: "renews_on",
  chargesQuantity: "charges_quantity",
  actionMenu: "",
  couponCode: "",
};

export const possibleColumnsToRender: ColumnsPossibleToRenderProp = {
  customer: {
    header: t([localeRoot, "customer_column"]),
    accessorKey: "customer",
    cell: CustomerColumn,
    thClassName: "",
    cellClassName: "",
  },
  paywall: {
    header: t([localeRoot, "paywall_column"]),
    accessorKey: "paywall",
    cell: PaywallColumn,
    thClassName: "hidden md:table-cell",
    cellClassName: "hidden md:table-cell",
  },
  status: {
    header: t([localeRoot, "status_column"]),
    accessorKey: "status",
    cell: StatusColumn,
    thClassName: "sm:w-30 md:w-36",
    cellClassName: "",
  },
  term: {
    header: t([localeRoot, "term_column"]),
    accessorKey: "term",
    cell: TermColumn,
    thClassName: "",
    cellClassName: "",
  },
  chargesQuantity: {
    header: t([localeRoot, "charges_quantity_column"]),
    accessorKey: "chargesQuantity",
    cell: ChargesQuantityColumn,
    thClassName: "hidden lg:table-cell",
    cellClassName: "hidden lg:table-cell",
  },
  startDate: {
    header: t([localeRoot, "start_date_column"]),
    accessorKey: "startDate",
    cell: StartDateColumn,
    thClassName: "",
    cellClassName: "",
  },
  renewalDate: {
    header: t([localeRoot, "renewal_date_column"]),
    accessorKey: "renewalDate",
    cell: RenewalDateColumn,
    thClassName: "hidden md:table-cell",
    cellClassName: "hidden md:table-cell",
  },
  actionMenu: {
    header: "",
    accessorKey: "actionMenu",
    cell: ActionMenuColumn,
    thClassName: "",
    cellClassName: "",
    canSort: false,
  },
  couponCode: {
    header: t([localeRoot, "coupon_code_column"]),
    accessorKey: "couponCode",
    cell: CouponCodeColumn,
    thClassName: "",
    cellClassName: "",
    canSort: false,
  },
};

export const defaultColumnsToRender = [
  possibleColumnsToRender.customer,
  possibleColumnsToRender.status,
  possibleColumnsToRender.paywall,
  possibleColumnsToRender.chargesQuantity,
  possibleColumnsToRender.term,
  possibleColumnsToRender.startDate,
  possibleColumnsToRender.renewalDate,
  possibleColumnsToRender.actionMenu,
];
