import type { ActivityScoreFilterProps } from "./ActivityScoreFilter";
import { ActivityScoreFilter } from "./ActivityScoreFilter";
import { AvatarSet } from "./AvatarSet";
import { BioFilter } from "./BioFilter";
import { EmailFilter } from "./EmailFilter";
import { EventsFilter } from "./EventsFilter";
import { HasPastDueSubscriptionFilter } from "./HasPastDueSubscriptionFilter";
import { HeadlineFilter } from "./HeadlineFilter";
import { InvitationLinksFilter } from "./InvitationLinksFilter";
import { JoinedCommunityDaysAgoFilter } from "./JoinedCommunityDaysAgoFilter";
import { LastLoggedInDaysAgoFilter } from "./LastLoggedInDaysAgoFilter";
import { LevelFilter } from "./LevelFilter";
import { LocationFilter } from "./LocationFilter";
import { NameFilter } from "./NameFilter";
import { PaywallsFilter } from "./PaywallsFilter";
import { RoleFilter } from "./RoleFilter";
import { SpaceGroupsFilter, SpaceGroupsFilterV2 } from "./SpaceGroupsFilter";
import { SpacesFilter } from "./SpacesFilter";
import { StatusFilter } from "./StatusFilter";
import { MemberTagsFilter } from "./TagsFilter";

export const SharedFilters = {
  AvatarSet,
  RoleFilter,
  SpaceGroupsFilter,
  SpaceGroupsFilterV2,
  SpacesFilter,
  StatusFilter,
  MemberTagsFilter,
  PaywallsFilter,
  EventsFilter,
  HasPastDueSubscriptionFilter,
  JoinedCommunityDaysAgoFilter,
  LastLoggedInDaysAgoFilter,
  ActivityScoreFilter,
  NameFilter,
  HeadlineFilter,
  BioFilter,
  LocationFilter,
  EmailFilter,
  InvitationLinksFilter,
  LevelFilter,
} as const;

export type { ActivityScoreFilterProps };
