import { t } from "i18n-js";
import { useChangePlanModal } from "@circle-react/components/Modals/MemberProfileModal/Billing/Tabs/SubscriptionTab/ChangePlan/ChangePlanModal";
import { useApplyCouponModal } from "@circle-react-shared/Paywalls/Modals/ApplyCouponModal";
import { useCancelAndRefundPaywallSubscriptionModal } from "@circle-react-shared/Paywalls/Modals/CancelAndRefundPaywallSubscriptionModal";
import { usePaywallSubscriptionDetailsModal } from "@circle-react-shared/Paywalls/Modals/PaywallSubscriptionDetailsModal";
import { useResumePaywallSubscriptionModal } from "@circle-react-shared/Paywalls/Modals/ResumePaywallSubscriptionModal";
import { useAttributeSaleMenuItem } from "@circle-react-shared/Paywalls/hooks/useAttributeSaleMenuItem";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { useModalData } from "@circle-react-shared/uikit/ModalV2";
import { usePaywallSubscriptionListContext } from "../PaywallSubscriptionListContextProvider";

const defaultPolicies = {
  can_change_billing_cycle: false,
  can_admin_upgrade: false,
  can_trial_subscription_change_plan: false,
  can_resume: false,
  can_change_plan: false,
  can_cancel: false,
};

interface MenuItemProp {
  key: string;
  label: string;
  onClick: (event: any) => void;
}

const buildMenuItems = ({
  subscription,
  subscriptionDetailModal,
  changePlanModal,
  resumeSubscriptionModal,
  cancelAndRefundSubscriptionModal,
  applyCouponModal,
  attributeSaleMenuItem,
  onSuccess,
}: any) => {
  const menuItems: MenuItemProp[] = [];
  const {
    can_change_billing_cycle: canChangeBillingCycle,
    can_admin_upgrade: canUpgrade,
    can_trial_subscription_change_plan: canTrialSubscriptionChangePlan,
    can_resume: canResume,
    can_change_plan: canChangePlan,
    can_cancel: canCancel,
    can_apply_coupon: canApplyCoupon,
    can_remove_coupon: canRemoveCoupon,
  } = subscription.policies ?? defaultPolicies;

  menuItems.push({
    key: "subscription-details",
    label: t(
      "settings.paywalls_admin_dashboard.subscriptions.list.dropdown_menu.details",
    ),
    onClick: e => {
      subscriptionDetailModal.show({ subscription });
      e.stopPropagation();
    },
  });

  if (canApplyCoupon) {
    menuItems.push({
      key: "apply-coupon",
      label: t(
        "settings.paywalls_admin_dashboard.subscriptions.list.dropdown_menu.apply_coupon",
      ),
      onClick: e => {
        applyCouponModal.show({ subscription });
        e.stopPropagation();
      },
    });
  }

  if (canRemoveCoupon) {
    menuItems.push({
      key: "remove-coupon",
      label: t(
        "settings.paywalls_admin_dashboard.subscriptions.list.dropdown_menu.remove_coupon",
      ),
      onClick: e => {
        applyCouponModal.show({ subscription, shouldRemoveCoupon: true });
        e.stopPropagation();
      },
    });
  }

  if (attributeSaleMenuItem) menuItems.push(attributeSaleMenuItem);

  if (canChangeBillingCycle) {
    menuItems.push({
      key: "subscription-change-billing-cycle",
      label: t(
        "member_profile_modal.billing.subscriptions.actions.change_billing_cycle",
      ),
      onClick: e => {
        changePlanModal.show({
          refetchSubscriptions: onSuccess,
          subscription: subscription,
          changePlan: false,
          isManagingMember: true,
        });
        e.stopPropagation();
      },
    });
  }

  if (canChangePlan) {
    menuItems.push({
      key: "subscription-change-plan",
      label: t(
        "member_profile_modal.billing.subscriptions.actions.change_plan",
      ),
      onClick: e => {
        changePlanModal.show({
          refetchSubscriptions: onSuccess,
          subscription: subscription,
          changePlan: true,
          isManagingMember: true,
        });
        e.stopPropagation();
      },
    });
  }

  if (canUpgrade) {
    menuItems.push({
      key: "subscription-trial-upgrade",
      label: t("member_profile_modal.billing.subscriptions.actions.upgrade"),
      onClick: e => {
        changePlanModal.show({
          refetchSubscriptions: onSuccess,
          subscription,
          changePlan: canTrialSubscriptionChangePlan,
          isManagingMember: true,
        });
        e.stopPropagation();
      },
    });
  }

  if (canResume) {
    menuItems.push({
      key: "resume-subscription",
      label: t(
        "settings.paywalls_admin_dashboard.subscriptions.list.dropdown_menu.resume",
      ),
      onClick: e => {
        resumeSubscriptionModal.show({
          subscription,
          onSuccess: onSuccess,
        });
        e.stopPropagation();
      },
    });
  }

  if (canCancel) {
    menuItems.push({
      key: "cancel-and-refund-subscription",
      label: t(
        "settings.paywalls_admin_dashboard.subscriptions.list.dropdown_menu.cancel",
      ),
      onClick: e => {
        cancelAndRefundSubscriptionModal.show({
          subscription,
          onSuccess: onSuccess,
        });
        e.stopPropagation();
      },
    });
  }

  return menuItems;
};

export const ActionMenuColumn = (cellContext: any) => {
  const { contentPortalElement } = useModalData();

  const {
    row: { original: subscription },
  } = cellContext;

  const { variant: variantSubscriptionList, refetchAllSubscriptions } =
    usePaywallSubscriptionListContext();

  const changePlanModal = useChangePlanModal();
  const cancelAndRefundSubscriptionModal =
    useCancelAndRefundPaywallSubscriptionModal();
  const subscriptionDetailModal = usePaywallSubscriptionDetailsModal(
    variantSubscriptionList,
  );
  const resumeSubscriptionModal = useResumePaywallSubscriptionModal();
  const attributeSaleMenuItem = useAttributeSaleMenuItem({
    attributable: subscription,
    attributableType: "CommunityMemberSubscription",
    onSuccess: refetchAllSubscriptions,
  });
  const applyCouponModal = useApplyCouponModal();

  const menuItems = buildMenuItems({
    subscription,
    changePlanModal,
    cancelAndRefundSubscriptionModal,
    subscriptionDetailModal,
    resumeSubscriptionModal,
    applyCouponModal,
    attributeSaleMenuItem,
    onSuccess: refetchAllSubscriptions,
  });

  return (
    <DataTable.Actions
      contentPortalElement={contentPortalElement ?? document.body}
    >
      {menuItems.map(({ key, label, onClick }) => (
        <DataTable.Actions.Item key={key} onClick={onClick}>
          {label}
        </DataTable.Actions.Item>
      ))}
    </DataTable.Actions>
  );
};
