import { Fragment } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { DROPDOWN_SIZE, Dropdown } from "@circle-react-uikit/Dropdown";
import { Typography } from "@circle-react-uikit/Typography";

export const AddFilterDropdown = ({
  children,
  label = t("settings.manage_members.add_filter"),
}) => (
  <Dropdown
    direction="bottom-start"
    dataTestId="add-filter-dropdown"
    as={Fragment}
    buttonWrapperComponent="li"
    size={DROPDOWN_SIZE.EXTRA_LARGE}
    buttonClassName="flex h-6 justify-center items-center hover:bg-secondary focus-visible:bg-secondary gap-1 rounded-md"
    button={
      <>
        <Icon type="20-add-sm" size={20} aria-hidden className="text-darkest" />
        <Typography.LabelSm weight="medium">
          <span className="text-darkest pr-2">{label}</span>
        </Typography.LabelSm>
      </>
    }
  >
    {children}
  </Dropdown>
);

AddFilterDropdown.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
};
