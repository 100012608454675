import { t } from "i18n-js";
// Columns components
import { ActionMenuColumn } from "./ActionMenuColumn";
import { AmountColumn } from "./AmountColumn";
import { CreatedAtColumn } from "./CreatedAtColumn";
import { CustomerColumn } from "./CustomerColumn";
import { PaymentMethodColumn } from "./PaymentMethodColumn";
import { PaywallColumn } from "./PaywallColumn";
import { StatusColumn } from "./StatusColumn";
import { TermColumn } from "./TermColumn";

const localeRoot = "settings.paywalls_admin_dashboard.charges.list.headers";

export type COLUMNS_AVAILABLE =
  | "customer"
  | "status"
  | "amount"
  | "term"
  | "paywall"
  | "createdAt"
  | "actionMenu"
  | "paymentMethod";

interface CellProp {
  header: string;
  accessorKey: COLUMNS_AVAILABLE;
  cell: any;
  thClassName?: string;
  cellClassName?: string;
  canSort?: boolean;
}
type ColumnsPossibleToRenderProp = Record<COLUMNS_AVAILABLE, CellProp>;

export const orderingColumn: Record<COLUMNS_AVAILABLE, string> = {
  customer: "community_member_name",
  amount: "amount",
  paywall: "paywall_name",
  status: "display_status",
  term: "charge_term",
  createdAt: "created_at",
  paymentMethod: "payment_method_type",
  actionMenu: "",
};

export const possibleColumnsToRender: ColumnsPossibleToRenderProp = {
  customer: {
    header: t([localeRoot, "customer_column"]),
    accessorKey: "customer",
    cell: CustomerColumn,
    thClassName: "",
    cellClassName: "",
  },
  paymentMethod: {
    header: t([localeRoot, "payment_method"]),
    accessorKey: "paymentMethod",
    cell: PaymentMethodColumn,
    thClassName: "",
    cellClassName: "items-center",
    canSort: false,
  },
  term: {
    header: t([localeRoot, "term_column"]),
    accessorKey: "term",
    cell: TermColumn,
    thClassName: "hidden lg:table-cell",
    cellClassName: "hidden lg:table-cell",
  },
  amount: {
    header: t([localeRoot, "amount_column"]),
    accessorKey: "amount",
    cell: AmountColumn,
    thClassName: "",
    cellClassName: "",
  },
  status: {
    header: t([localeRoot, "status_column"]),
    accessorKey: "status",
    cell: StatusColumn,
    thClassName: "",
    cellClassName: "",
  },
  paywall: {
    header: t([localeRoot, "paywall_name_column"]),
    accessorKey: "paywall",
    cell: PaywallColumn,
    thClassName: "hidden md:table-cell",
    cellClassName: "hidden md:table-cell",
  },
  createdAt: {
    header: t([localeRoot, "date_column"]),
    accessorKey: "createdAt",
    cell: CreatedAtColumn,
    thClassName: "",
    cellClassName: "",
  },
  actionMenu: {
    header: "",
    accessorKey: "actionMenu",
    cell: ActionMenuColumn,
    thClassName: "",
    cellClassName: "",
    canSort: false,
  },
};

export const defaultColumnsToRender = [
  possibleColumnsToRender.customer,
  possibleColumnsToRender.term,
  possibleColumnsToRender.amount,
  possibleColumnsToRender.paymentMethod,
  possibleColumnsToRender.status,
  possibleColumnsToRender.paywall,
  possibleColumnsToRender.createdAt,
  possibleColumnsToRender.actionMenu,
];
