import PropTypes from "prop-types";
import classNames from "classnames";
import { Canvas } from "@circle-react-shared/Canvas";

export const CanvasSize = ({ screenSize = "desktop", className, children }) => (
  <div
    className={classNames(
      "h-full w-full p-8",
      {
        "max-w-full": screenSize === "desktop",
        "max-w-3xl": screenSize === "tablet",
        "max-w-sm": screenSize === "mobile",
      },
      className,
    )}
  >
    <Canvas className="overflow-hidden">{children}</Canvas>
  </div>
);

CanvasSize.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  screenSize: PropTypes.oneOf(["mobile", "tablet", "desktop"]),
};
