import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import type { ReactNode } from "react";
import { Popover } from "@headlessui/react";
import { t } from "i18n-js";
import delay from "lodash/delay";
import isFunction from "lodash/isFunction";
import { usePopper } from "react-popper";
import invariant from "tiny-invariant";
import { Icon } from "@circle-react-shared/Icon";
import { TruncateText } from "@circle-react-shared/uikit/TruncateText";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { FilterChip } from "./FilterChip";
import type { SelectedText } from "./types";

interface FilterContextValue {
  selectedText: SelectedText;
  onApply: (value: any) => void;
  close: () => void;
}

const FilterContext = createContext<FilterContextValue | null>(null);

export const useFilterContext = () => {
  const filterContext = useContext(FilterContext);
  invariant(filterContext, "useFilterContext must be used within Filter");
  return filterContext;
};

FilterContext.displayName = "FilterContext";

interface FilterProps {
  /** Title for popover */
  title?: ReactNode;
  /** Content used on the filter chip */
  chip: string;
  /** Display text added in the chip */
  selectedText?: any;
  /** Customise the text displayed inside chip completely  */
  renderSelectedText?: ({ selectedText }: { selectedText: any }) => ReactNode;
  /**
   * The actual inputs. Bring your own or use one of the existing ones in FilterTypes.
   * You can provide a single component which will be provided with `onApply` callback to pass in the selectedText
   * If you end up having multiple elements or a fragment which cannot receive pass in props, you can choose to pass in a function instead
   */
  children: ReactNode | ((props: FilterContextValue) => ReactNode);
  /**
   * Callback fired on applying a filter
   * Contains value transmitted from selected filter
   * May be different from text displayed
   */
  onApply: (value: any) => void;
  /** Open the filter automatically */
  isInitialOpen?: boolean;
  shouldShowRemoveButton?: boolean;
  shouldShowClearButton?: boolean;
}

/**
 * The new V2 Filters
 * Supports any type of filters in popover as provided
 */
export const Filter = ({
  children,
  title = null,
  chip,
  selectedText = null,
  renderSelectedText,
  onApply,
  isInitialOpen,
  shouldShowRemoveButton = false,
  shouldShowClearButton = true,
}: FilterProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLLIElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  useEffect(() => {
    if (referenceElement && isInitialOpen) {
      delay(() => {
        referenceElement.click();
      }, 100);
    }
  }, [referenceElement]);

  return (
    <Popover as={Fragment}>
      {({ open, close }: any) => {
        const handleApply = (value: any) => {
          close();
          onApply(value);
        };
        const childrenProps = { selectedText, onApply: handleApply, close };
        return (
          <FilterContext.Provider value={childrenProps}>
            <Popover.Button
              ref={setReferenceElement}
              className="focus-visible:ring-secondary rounded-3xl focus-visible:ring-1"
              as="li"
            >
              <FilterChip
                isFocused={open}
                selectedText={selectedText}
                renderSelectedText={renderSelectedText}
                onClear={() => handleApply(null)}
                shouldShowClearButton={shouldShowClearButton}
              >
                {chip}
              </FilterChip>
            </Popover.Button>
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              className="isolate z-10 w-[336px] max-w-md"
              data-testid="filter_component"
              {...attributes.popper}
            >
              <div className="bg-primary border-primary mt-1 w-full rounded-lg border shadow-xl">
                {title && (
                  <div className="border-b-primary flex items-center justify-between border-b px-4 py-3">
                    <TruncateText>
                      <Typography.LabelMd weight="semibold" truncate>
                        {title}
                      </Typography.LabelMd>
                    </TruncateText>
                    {shouldShowRemoveButton && (
                      <button
                        title={t(
                          "members_directory.header.search.remove_filter",
                        )}
                        type="button"
                        className="text-default"
                        onClick={() => handleApply(null)}
                      >
                        <Icon
                          size={16}
                          type="16-trash-bin"
                          useWithStrokeCurrentColor
                        />
                      </button>
                    )}
                  </div>
                )}
                {isFunction(children) ? children(childrenProps) : children}
              </div>
            </Popover.Panel>
          </FilterContext.Provider>
        );
      }}
    </Popover>
  );
};
