import { DataTable } from "@circle-react-uikit/DataTableV2";
import { usePaywallSubscriptionList } from "./hooks/usePaywallSubscriptionList";

export {
  PaywallSubscriptionListContextProvider,
  usePaywallSubscriptionListContext,
} from "./PaywallSubscriptionListContextProvider";

export const PaywallSubscriptionList = ({
  columnsToRender,
  subscriptionData,
  onClickPagination,
  className = "",
}) => {
  const { onChangeSorting } = usePaywallSubscriptionList({ subscriptionData });

  return (
    <div className={className}>
      <DataTable
        onChangeSorting={onChangeSorting}
        columns={columnsToRender}
        data={subscriptionData?.subscriptions ?? []}
        isLoading={subscriptionData?.isLoadingSubscriptions}
        // Pagination
        initialPage={subscriptionData?.page ?? 1}
        onChangePage={onClickPagination}
        totalRecordsCount={subscriptionData?.totalSubscriptions}
        pageSize={subscriptionData.perPage}
      >
        <DataTable.Table>
          <DataTable.SortableHead />
          <DataTable.Loader />
          <DataTable.Body />
        </DataTable.Table>
        <DataTable.Pagination align="left" direction="reverse" />
      </DataTable>
    </div>
  );
};
