import PropTypes from "prop-types";
import { CanvasSize } from "../CanvasSize";
import { LockscreenFrame } from "./LockscreenFrame";

export const LockscreenCanvas = ({ screenSize = "desktop", ...rest }) => (
  <CanvasSize screenSize={screenSize}>
    <LockscreenFrame {...rest} />
  </CanvasSize>
);

LockscreenCanvas.propTypes = {
  screenSize: PropTypes.oneOf(["mobile", "tablet", "desktop"]),
  ...LockscreenFrame.propTypes,
};
