import type { ReactNode } from "react";
import { useMemo } from "react";
import type { BroadcastContentAttributes } from "@circle-react/components/SettingsApp/Emails/hooks/useGetBroadcast";
import { Form } from "@circle-react-shared/uikit/Form";
import type { OnSubmitFunction } from "@circle-react-shared/uikit/Form/Form";
import { Loader } from "@circle-react-shared/uikit/Loader";
import { useBroadcastData } from "../BroadcastDataContext";

interface EmailEditorFormProps {
  children: ReactNode;
  onSubmit: OnSubmitFunction<{
    email_content: BroadcastContentAttributes["email_content"];
  }>;
}

export function EmailEditorForm({ children, onSubmit }: EmailEditorFormProps) {
  const { data, isLoading } = useBroadcastData();

  const defaultValue = useMemo(() => {
    if (!data?.broadcast_content?.email_content) {
      return {
        email_content: {
          body: "",
        },
      };
    }
    return {
      email_content: data.broadcast_content.email_content,
    };
  }, [data]);

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValue}
      shouldStopPropagation
      className="h-full"
      formClassName="h-full grid grid-rows-[auto,auto,minmax(250px,1fr)]"
    >
      {children}
    </Form>
  );
}
