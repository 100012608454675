import { CanvasSize } from "../CanvasSize";
import type { SpaceFrameProps } from "./SpaceFrame";
import { SpaceFrame } from "./SpaceFrame";

export interface SpaceCanvasProps extends SpaceFrameProps {
  screenSize?: "desktop" | "tablet" | "mobile";
}

export const SpaceCanvas = ({
  screenSize = "desktop",
  ...rest
}: SpaceCanvasProps) => (
  <CanvasSize screenSize={screenSize}>
    <SpaceFrame screenSize={screenSize} {...rest} />
  </CanvasSize>
);
