import { DataTable } from "@circle-react-uikit/DataTableV2";
import { usePaywallChargesList } from "./hooks/usePaywallChargesList";

export {
  PaywallChargesListContextProvider,
  usePaywallChargesListContext,
} from "./PaywallChargesListContextProvider";

export const PaywallChargesList = ({
  columnsToRender,
  chargesData,
  onClickPagination,
  className = "",
}) => {
  const { onChangeSorting } = usePaywallChargesList({ chargesData });

  return (
    <div className={className}>
      <DataTable
        onChangeSorting={onChangeSorting}
        columns={columnsToRender}
        data={chargesData?.charges ?? []}
        isLoading={chargesData?.isLoadingCharges}
        // Pagination
        initialPage={chargesData?.page ?? 1}
        onChangePage={onClickPagination}
        totalRecordsCount={chargesData?.totalCharges}
        pageSize={chargesData.perPage}
      >
        <DataTable.Table>
          <DataTable.SortableHead />
          <DataTable.Loader />
          <DataTable.Body />
        </DataTable.Table>
        <DataTable.Pagination align="left" direction="reverse" />
      </DataTable>
    </div>
  );
};
