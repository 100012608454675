import { createContext, useContext } from "react";
import { noop } from "lodash";

interface PaywallChargesListContextProviderProps {
  children: JSX.Element;
  variant: "admin-charges-list" | "admin-member-edit-charges-list";
  shouldOpenAdminMemberEdit: boolean;
  refetchAllCharges: () => void;
}

const defaultValues = {
  shouldOpenAdminMemberEdit: true,
  variant: "admin-charges-list",
  refetchAllCharges: noop,
};

const PaywallChargesListContext = createContext(defaultValues);
PaywallChargesListContext.displayName = "PaywallChargesListContext";

export const PaywallChargesListContextProvider = ({
  children,
  shouldOpenAdminMemberEdit,
  variant,
  refetchAllCharges,
}: PaywallChargesListContextProviderProps) => {
  const valuesToStore = {
    shouldOpenAdminMemberEdit,
    variant,
    refetchAllCharges,
  };

  return (
    <PaywallChargesListContext.Provider value={{ ...valuesToStore }}>
      {children}
    </PaywallChargesListContext.Provider>
  );
};

export const usePaywallChargesListContext = () =>
  useContext(PaywallChargesListContext);
