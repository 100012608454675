import { useEffect, useMemo } from "react";
import { pricesEligibleForTrial } from "@circle-react/helpers/paywallPriceHelpers";
import { useCreatePaywallSubscriptionFormContext } from "../CreatePaywallSubscriptionFormContextProvider";

export const usePaywallPriceForm = ({ paywall }) => {
  const [paywallPriceOptions, paywallPriceMappedById] = useMemo(() => {
    const prices = paywall?.prices;
    const paywallPriceOptions = [];
    const paywallPriceMappedById = {};

    if (!prices) return [];

    pricesEligibleForTrial(prices).forEach(paywallPrice => {
      paywallPriceMappedById[paywallPrice.id] = paywallPrice;
      paywallPriceOptions.push({
        label: paywallPrice.short_description,
        value: paywallPrice.id,
      });
    });

    return [paywallPriceOptions, paywallPriceMappedById];
  }, [paywall]);

  const { setPaywallPricesIndex } = useCreatePaywallSubscriptionFormContext();

  useEffect(() => {
    setPaywallPricesIndex(paywallPriceMappedById ?? {});
  }, [paywallPriceMappedById, setPaywallPricesIndex]);

  return {
    paywallPriceOptions: paywallPriceOptions ?? [],
  };
};
