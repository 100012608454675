import { Fragment, useRef, useState } from "react";
import { Popover } from "@headlessui/react";
import { useInteractOutside } from "@react-aria/interactions";
import type { Editor } from "@tiptap/react";
import { t } from "i18n-js";
import { usePopper } from "react-popper";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { useButtonDropdownContext } from "./ButtonDropdownContext";
import { ButtonForm } from "./ButtonForm";

interface CTADropdownProps {
  editor: Editor;
}

export const CTADropdown = ({ editor }: CTADropdownProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const popperElementRef = useRef<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElementRef.current,
    {
      placement: "bottom-start",
    },
  );
  const { isDropdownOpen, toggleDropdownOpen } = useButtonDropdownContext();
  useInteractOutside({
    ref: popperElementRef,
    onInteractOutside: toggleDropdownOpen,
    isDisabled: !isDropdownOpen,
  });

  return (
    <div className="px-2">
      <Popover as={Fragment}>
        <Popover.Button
          ref={setReferenceElement}
          className="focus-visible:ring-secondary text-default hover:bg-secondary flex items-center gap-x-1 rounded-md px-2 py-1.5 text-sm focus-visible:ring-1"
          as="button"
          onClick={toggleDropdownOpen}
        >
          <Typography.LabelMd weight="medium" color="text-default">
            {t("email_editor.cta.button")}
          </Typography.LabelMd>
          <Icon type="20-chevron-down-sm" size={20} />
        </Popover.Button>
        <Popover.Panel
          ref={popperElementRef}
          style={styles.popper}
          className="isolate z-10 w-full max-w-sm"
          focus
          static
          {...attributes.popper}
        >
          {isDropdownOpen && (
            <ButtonForm editor={editor} onClose={toggleDropdownOpen} />
          )}
        </Popover.Panel>
      </Popover>
    </div>
  );
};
