import { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export const Canvas = forwardRef(({ children, className, ...rest }, ref) => (
  <div
    ref={ref}
    className={classNames(
      "border-primary bg-primary relative isolate h-full w-full rounded-lg border shadow-lg",
      className,
    )}
    {...rest}
  >
    {children}
  </div>
));
Canvas.displayName = "Canvas";

Canvas.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
