import type { ReactNode } from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { t } from "i18n-js";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { ListItem } from "./ListItem";

export interface InteractiveListProps {
  items: any[];
  onSelect: (item: any, event: any) => void;
  getKey?: (item: any) => string;
  getItemContent: (item: any) => ReactNode;
  subsectionKey: string;
  loading: boolean;
  notFoundContent?: string;
  disableHorizontalArrowKeys?: boolean;
}

export const InteractiveList = ({
  items,
  onSelect,
  getKey,
  getItemContent,
  subsectionKey,
  loading,
  notFoundContent = t("search.results.zero"),
  disableHorizontalArrowKeys = false,
}: InteractiveListProps) => {
  const [selectedId, setSelectedId] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const markListItemAsSelected = (index: number) => {
    setSelectedId(index);
    const containerElement = containerRef.current;
    if (!containerElement) {
      return;
    }
    const selectedDOMEl = containerElement.querySelectorAll("li")[index];
    // Note: TODO: replace with scrollIntoViewIfNeeded.
    // To do that we will have to limit the maxHeight of the block selector dropdown as right now
    // its more then 50% of the screen height.
    containerElement.scrollTop = 0;
    containerElement.scrollTop =
      selectedDOMEl.getBoundingClientRect().bottom -
      containerElement.getBoundingClientRect().top -
      containerElement.offsetHeight;
  };

  useEffect(() => {
    setSelectedId(0);
  }, [items]);

  useEffect(() => {
    const onKeydown = (event: any) => {
      switch (event.key) {
        case "ArrowDown":
          {
            event.preventDefault();
            const index = (selectedId + 1) % items.length;
            markListItemAsSelected(index);
          }
          break;
        case "ArrowUp":
          {
            event.preventDefault();
            const index = (items.length + selectedId - 1) % items.length;
            markListItemAsSelected(index);
          }
          break;
        case "ArrowLeft":
          disableHorizontalArrowKeys && event.preventDefault();
          break;
        case "ArrowRight":
          disableHorizontalArrowKeys && event.preventDefault();
          break;
        case "Enter":
          event.preventDefault();
          if (selectedId >= 0) {
            onSelect(items[selectedId], event);
          }
          break;
      }
    };
    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  }, [selectedId, items, onSelect, disableHorizontalArrowKeys]);

  let currentSection: any = null;

  return (
    <div
      ref={containerRef}
      className="react-interactive-list trix__dropdown ml-2"
    >
      <ul className="bg-secondary text-dark rounded-lg">
        {loading && (
          <li>
            <Loader />
          </li>
        )}
        {!loading &&
          items.length > 0 &&
          items.map((item, index) => {
            const key = getKey ? getKey(item) : index;
            const subsection = item[subsectionKey];
            const showSectionItem = subsection && currentSection !== subsection;
            currentSection = subsection;
            return (
              <Fragment key={`${subsection}-${showSectionItem}`}>
                {showSectionItem && (
                  <div className="trix__dropdown--subsection">{subsection}</div>
                )}
                <ListItem
                  selected={selectedId === index}
                  item={item}
                  key={key}
                  onSelect={onSelect}
                  content={getItemContent(item)}
                />
              </Fragment>
            );
          })}
        {!loading && items.length <= 0 && (
          <li className="border-secondary rounded-lg">
            <Typography.LabelSm color="text-default">
              {notFoundContent}
            </Typography.LabelSm>
          </li>
        )}
      </ul>
    </div>
  );
};
